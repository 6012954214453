<template>
  <div class="wrapper">
    <div class="header">
      {{ hb.contentType | typePipe }} > {{ hb.taskName | stepPipe }}
    </div>
    <HbConstrastData class="t-r-btn" :hb="hb" />
    <div
      v-if="showConfig"
      style="
        min-height: 3em;
        padding: 1em 0 1em 1em;
        border: 1px solid #ddd;
        border-radius: 4px;
      "
    >
      <ConfigPanel :hb="hb" />
    </div>
    <div class="flexinfo">
      <div class="item1">
        <Input
          v-model="hb.title"
          :placeholder="`请输入${
            /Verse/.test(hb.contentType) ? '韵文标题' : '主标题'
          }`"
          :rows="4"
          :disabled="!editable"
        />
      </div>
      <div class="item2">
        <AgeSelect :ageType.sync="hb.ageType" :disabled="!editable" />
        <Category
          :disabled="!editable"
          :firstCode="hb.contentType"
          ref="categories"
          :thirdCode.sync="hb.secondCategory"
          :secondCode.sync="hb.category"
        />
        <!-- 只有长难句才有序号 -->
        <div v-if="/LongSentence/.test(hb.contentType)">
          排序：
          <InputNumber
            placeholder="序号"
            v-model="hb.sort"
            style="width: 5em"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hb: {
      required: true,
    },
    editable: {
      default: true,
    },
    showConfig: {
      default: false,
    },
  },
  computed: {
    secondTypes() {
      return this.$refs.categories.secondTypes;
    },
    thirdTypes() {
      return this.$refs.categories.thirdTypes;
    },
  },
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.header {
  font-size: 16px;
  width: 10em;
  text-align: center;
  margin: 0.5em auto;
  font-weight: bold;
}
.wrapper {
  padding-bottom: 0.75em;
  max-width: 750px;
  margin: auto;

  position: relative;
  .t-r-btn {
    position: absolute;
    top: -3px;
    right: 0;
  }
}
.flexinfo {
  display: flex;
  flex-wrap: wrap;
  .item1 {
    margin-top: 0.5em;
    width: 50%;
    padding-right: 3em;
  }
  .item2 {
    margin-top: 0.5em;
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
}
::v-deep [disabled],
::v-deep .ivu-select-selection {
  color: unset;
}
</style>